import React from "react";
import { RootState } from "@/store";
import Image from "next/image";
import CardInitials from "../Cards/CardInitials";
import { IAvatarProps } from "./interfaces";
import { useSelector } from "react-redux";

import styles from "./styles.module.scss";

const Avatar = ({ className, classNameHat, classNameImage }: IAvatarProps) => {
  const username = useSelector((state: RootState) => state.user.username);
  const url = useSelector((state: RootState) => state.user.url);

  return (
    <div className={className ? className : styles.avatar__wrapper}>
      {url ? (
        <Image className={styles.avatar__image} src={url} width={68} height={68} alt="hat on avatar" loading="lazy" />
      ) : (
        <CardInitials
          className={classNameImage ? "" : styles.avatar__initials}
          username={username}
        />
      )}
    </div>
  );
};

export default React.memo(Avatar);

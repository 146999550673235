import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store";
import { Application } from "pixi.js";
import HouseCard from "@/components/Cards/HouseCard";
import ShipCard from "@/components/Cards/ShipCard";
import { SHIP_COORDINATES, SHIP_IMAGE } from "@/constants/buildings";
import { setCanvas, setIsLoading } from "@/store/app/appSlice";
import { selectHouseByName, setIsTraveling } from "@/store/island/islandSlice";
import { HOUSES } from "@/store/island/island.mockData";
import { getLocalStorageItem } from "@/utils/localStorage";
import { TUTORIALS } from "@/constants/localStorage";
import {
  increaseStep,
  setActiveTutorial,
  setIsActive,
} from "@/store/tutorial/tutorialSlice";
import { PRIZE } from "@/constants/cookies";
import question from "@/assets/icons/question.png";
import Text from "@/components/Text";
import { getTranslation } from "@/utils/location";
import PreloadingContext from "@/context/usePreloading";
import Link from "next/link";
import { ROUTES } from "@/constants";
import Image from "next/image";

import styles from "./styles.module.scss";
import AdsChest  from "@/components/Ads/AdsChest";

export default function MenuCanvas() {
  const dispatch = useDispatch();
  const canvasRef = useRef<HTMLDivElement | null>(null);
  const appRef = useRef<Application | null>(null);
  const { houses } = useSelector((state: RootState) => state.island);
  const house = useSelector((state: RootState) =>
    selectHouseByName(state, HOUSES[0].house_type_name)
  );
  const adsChest = Boolean(useSelector((state: RootState) => state.ads.modal));
  const { ws } = useSelector((state: RootState) => state.sockets);
  const [showShip, setShowShip] = useState(false);
  const [isShipVisible, setIsShipVisible] = useState(false);
  const isSuperBoostserActive = useSelector(
    (state: RootState) => state.user.balance.super_boster?.super_auto_collect
  );
  const initializeTutorial = useCallback(() => {
    if (getLocalStorageItem(PRIZE)) return;

    const mainTutorial = getLocalStorageItem(TUTORIALS.MAIN);

    if (!mainTutorial) {
      dispatch(setActiveTutorial(TUTORIALS.MAIN));
      dispatch(setIsActive(true));
      dispatch(increaseStep());
    } else {
      dispatch(setIsActive(false));
    }
  }, [dispatch]);

  const setupCanvas = useCallback(async () => {
    setTimeout(() => {
      if (ws?.readyState === 1) {
        dispatch(setIsLoading(false));
      }
    }, 400);

    if (canvasRef.current) {
      dispatch(setCanvas(canvasRef.current));
    }

    return () => {
      if (appRef.current) {
        appRef.current.destroy(true, { children: true });
        appRef.current = null;
      }
    };
  }, [dispatch, ws]);

  const isTraveling = useMemo(
    () =>
      house?.time_remaining_to_full_resources !== undefined &&
      house.time_remaining_to_full_resources > 0,
    [house]
  );

  const handleTravelingState = useCallback(() => {
    if (isTraveling) {
      setShowShip(true);

      const timer = setTimeout(() => {
        setIsShipVisible(false);
      }, 1100);

      return () => clearTimeout(timer);
    } else {
      setShowShip(true);
      setIsShipVisible(true);
    }

    dispatch(setIsTraveling(!(showShip && isShipVisible)));
  }, [dispatch, isTraveling, showShip, isShipVisible]);

  useEffect(() => {
    initializeTutorial();
  }, [initializeTutorial]);

  useEffect(() => {
    setupCanvas();
  }, [setupCanvas]);

  useEffect(() => {
    handleTravelingState();
  }, [handleTravelingState]);

  if (houses.length < 1) return null;

  return (
    <div className={styles.canvas}>
      <div className={styles.canvas__ads}>
        <PreloadingContext>{adsChest && <AdsChest />}</PreloadingContext>
      </div>
      <PreloadingContext>
        {isSuperBoostserActive && (
          <Text className={styles.canvas__text__info}>
            <div className={styles.canvas__shine__wrapper}>
              {getTranslation("Синхронный сбор")}
              <Link href={ROUTES.AUTOBOOSTERS_INFO.url}>
                <Image
                  src={question}
                  alt="ton"
                  width={32}
                  height={32}
                  quality={100}
                />
              </Link>
            </div>
          </Text>
        )}
        {houses.map((house) => (
          <HouseCard
            name={house.house_type_name}
            key={house.house_type_name}
            images={house.images}
            coordinates={house.coordinates}
          />
        ))}
      </PreloadingContext>

      {showShip && isShipVisible && (
        <ShipCard image={SHIP_IMAGE} coordinates={SHIP_COORDINATES} />
      )}
    </div>
  );
}

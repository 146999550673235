import { useRouter } from "next/router";
import useClickSound from "@/hooks/useClickSound";
import React, { useCallback, useEffect, useState } from "react";
import leaderboard from "@/assets/hud/leaderboard.png";
import chest from "@/assets/hud/chest.png";
import starterPack from "@/assets/icons/starterPackChest.png";
import dailyLeaderboard from "@/assets/hud/dailyLeaderboard.png";
import map from "@/assets/hud/map.png";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import Image from "next/image";
import StartPack from "../NotifyModal/Layouts/StartPack";
import { clearStarterPack } from "@/store/starterPack/starterPackSlice";
import Link from "next/link";
import { ROUTES } from "@/constants";
import walletIcon from "@/assets/hud/walletHudIcon.png"


import styles from "./styles.module.scss";

const ROUTES_HUD_BUTTONS = {
    CHEST: "/chest",
    MAP: "/map",
    LEADERBOARD: "/leaderboard",
    DAILY_LEADERBOARD: "/daily-leaderboard",
};

const HudButtons = () => {
    const router = useRouter();
    const playSound = useClickSound();
    const [showStarterPackModal, setShowStarterPackModal] = useState(false);
    const timeStamp = useSelector(
        (state: RootState) => state.starterPack.timer
    );
    const keys_cheast = useSelector(
        (state: RootState) => state.user.balance.keys_cheast
    );

    const [timeLeft, setTimeLeft] = useState<{
        minutes: number;
        seconds: number;
    } | null>(null);

    const handleOpenStarterPack = () => {
        setShowStarterPackModal(true);
    };

    const handleCloseModalStarterPack = () => {
        setShowStarterPackModal(false);
    };

    const handleNavigate = useCallback(
        (path: string) => {
            playSound();
            router.push(path);
        },
        [playSound, router]
    );

    useEffect(() => {
        const targetTime = timeStamp;

        if (!targetTime) return;

        const calculateTimeLeft = () => {
            const now = new Date().getTime();
            const difference = new Date(targetTime).getTime() - now;

            if (difference <= 0) {
                setTimeLeft(null);
                handleCloseModalStarterPack();
                clearStarterPack();
                return;
            }

            const minutes = Math.floor((difference / (1000 * 60)) % 60);
            const seconds = Math.floor((difference / 1000) % 60);

            setTimeLeft({ minutes, seconds });
        };

        calculateTimeLeft();

        const interval = setInterval(() => {
            calculateTimeLeft();
        }, 1000);

        return () => clearInterval(interval);
    }, [timeStamp]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.wrapper__right_column}>
                <div
                    className={styles.link__chest}
                    onClick={handleNavigate.bind(
                        null,
                        ROUTES_HUD_BUTTONS.CHEST
                    )}
                >
                    <Image
                        className={styles.image}
                        src={chest}
                        alt="chest"
                        width={48}
                        loading="lazy"
                        height={48}
                    />
                    <span className={styles.chest__quantity}>
                        X{keys_cheast}
                    </span>
                </div>
                {timeLeft && Boolean(timeStamp) && (
                    <div
                        onClick={handleOpenStarterPack}
                        className={styles.link__timer}
                    >
                        <Image
                            className={styles.image}
                            src={starterPack}
                            alt="chest"
                            width={48}
                            loading="lazy"
                            height={48}
                        />
                        <span className={styles.link__timer__time}>
                            {timeLeft.minutes.toString().padStart(2, "0")}:
                            {timeLeft.seconds.toString().padStart(2, "0")}
                        </span>
                    </div>
                )}
                 <Link href={ROUTES.WALLET.url} className={styles.link__wallet}>
                    <Image
                        className={styles.image_circle}
                        src={walletIcon}
                        alt="wallet"
                        width={48}
                        loading="lazy"
                        height={48}
                    />
                </Link>
            </div>
            <div className={styles.wrapper__left_column}>
                <div
                    className={styles.link}
                    onClick={handleNavigate.bind(null, ROUTES_HUD_BUTTONS.MAP)}
                >
                    <Image
                        className={styles.image}
                        src={map}
                        alt="map"
                        width={48}
                        height={48}
                        loading="lazy"
                    />
                </div>
                <div
                    className={styles.link}
                    onClick={handleNavigate.bind(
                        null,
                        ROUTES_HUD_BUTTONS.LEADERBOARD
                    )}
                >
                    <Image
                        className={styles.image}
                        src={leaderboard}
                        alt="leaderboard"
                        loading="lazy"
                        width={48}
                        height={48}
                    />
                </div>
                <div
                    className={styles.link}
                    onClick={handleNavigate.bind(
                        null,
                        ROUTES_HUD_BUTTONS.DAILY_LEADERBOARD
                    )}
                >
                    <Image
                        className={styles.image}
                        src={dailyLeaderboard}
                        alt="daily leaderboard"
                        loading="lazy"
                        width={48}
                        height={48}
                    />
                </div>
               
            </div>
            {showStarterPackModal && (
                <StartPack
                    time={`${timeLeft?.minutes}:${timeLeft?.seconds}`}
                    onClose={handleCloseModalStarterPack}
                />
            )}
        </div>
    );
};

export default React.memo(HudButtons);

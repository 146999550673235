import { createSlice } from "@reduxjs/toolkit";
import { IChestsState } from "./interfaces";

const initialState: IChestsState = {
  reward: {
    Duration: null,
    Multiplier: null,
    can_continue_booster: true,
    AllCount:0,
  },
  lastOpenedChestIndex: null,
};

export const chestsSlice = createSlice({
  name: "chests",
  initialState,
  reducers: {
    setReward: (state, action) => {
      state.reward = action.payload;
    },
    setLastOpenedChestIndex: (state, action) => {
      state.lastOpenedChestIndex = action.payload;
    },
  },
});

export const { setReward, setLastOpenedChestIndex } = chestsSlice.actions;
export default chestsSlice.reducer;

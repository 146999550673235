import { createSlice } from "@reduxjs/toolkit";
import { DayilyRewardStateType } from "./interface";

const initialState: DayilyRewardStateType = {
  rewards: null,
};
export const dailyRewardSlice = createSlice({
  name: "dailyReward",
  initialState,
  reducers: {
    setDailyReward: (state, action) => {
      state.rewards = action.payload.rewards;
    },
    clearDailyReward: (state) => {
      state.rewards = null;
    },
  },
});

export const { setDailyReward, clearDailyReward } = dailyRewardSlice.actions;
export default dailyRewardSlice.reducer;

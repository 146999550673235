import { RootState } from "@/store";
import { useDispatch, useSelector } from "react-redux";
import PortalModal from "@/components/PortalModal";
import saleWrapper from "@/assets/screens/shop/SaleBackground.png";
import close from "@/assets/icons/close.png";
import clock from "@/assets/icons/clock.png";
import gift from "@/assets/icons/gift.png";
import crossLine from "@/assets/icons/crossline.svg";
import Image from "next/image";
import ActionButton from "../ActionButton";
import { getTranslation } from "@/utils/location";
import { clearPopup } from "@/store/notification/notificationSlice";
import React, { useEffect } from "react";
import CardBonus from "../CardBonus";

import useToastManager from "@/hooks/useToastManager";
import useClickSound from "@/hooks/useClickSound";
import { preValidateXTRPurchase, validateXTRPurchase } from "@/api/payment";
import { useInvoice } from "@/hooks/useInvoice";
import {
  ACTIONS,
  getPopUpDataByNames,
} from "@/utils/getters/getPopUpDataByName";
import { usePopup } from "@/hooks/usePopUp";
import { sendWsEvent } from "@/utils/ws";
import { WebSocketsClientEvents } from "@/constants/events";
import { getImage } from "./image";

import styles from "./styles.module.scss";
import NotifyLayout from "./Layouts/Notification";
import DailyRewardLayout from "./Layouts/DailyReward";
import AdsLayout from "./Layouts/Ads";

const NotifyModal = () => {
  const user_popup_id = useSelector(
    (state: RootState) => state.notification.user_popup_id
  );

  const popUpData = useSelector((state: RootState) => state.notification.popup);
  const isOpen = Boolean(popUpData);

  const shopItem = useSelector((state: RootState) => state.notification.item);
  const ws = useSelector((state: RootState) => state.sockets.ws);
  const user_id = useSelector((state: RootState) => state.user.id);
  const isValidate = useSelector((state: RootState) => state.shop.isValidate);
  const webApp = useSelector((state: RootState) => state.app.webApp);
  const playSound = useClickSound();
  const [timer, setTimer] = React.useState<number | null>(
    popUpData?.timer || 60
  );
  const IS_GIFT = popUpData?.type == "gift";
  const cardTitle = shopItem?.title || "";
  const { createInvoice } = useInvoice();
  const { modal } = usePopup();
  const { notifyPending, updateToast, closePending } = useToastManager();
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(clearPopup());
    sendWsEvent(ws, WebSocketsClientEvents.SHOW_POPUP, {
      id: user_popup_id,
      ignored: true,
    });
    setTimer(null);
  };

  const handleClickBuy = async () => {
    if (isValidate) return;

    playSound();

    const toastId = notifyPending("Ваш заказ обрабатывается");
    const shop_item_id = shopItem?.id.toString() || "";
    const booster_type = "";
    const price = shopItem?.price_star || "";

    try {
      if (!webApp) {
        throw new Error("WebApp is not available");
      }

      preValidateXTRPurchase(
        user_id.toString(),
        shop_item_id,
        booster_type,
        price
      );

      await createInvoice(
        webApp,
        getTranslation(shopItem?.title || ""),
        Number(price)
      );

      const { transaction_id, success } = await validateXTRPurchase(
        user_id.toString(),
        shop_item_id,
        booster_type,
        price
      );

      if (success) {
        sendWsEvent(ws, WebSocketsClientEvents.SHOW_POPUP, {
          id: user_popup_id,
          ignored: false,
        });
        dispatch(clearPopup());
        setTimer(null);
        const { title, textButton, text } = getPopUpDataByNames(ACTIONS.BUY);
        modal(
          title,
          textButton,
          text + cardTitle.toUpperCase() + "!",
          shopItem?.image
        );
        updateToast(toastId, getTranslation("Ваш заказ успешно обработан!"));
        sendWsEvent(ws, WebSocketsClientEvents.PURCHASE_RECEIVED, {
          transaction_id: transaction_id,
          purchase_type: "stars",
        });
      } else {
        closePending(toastId, getTranslation("Ошибка валидации заказа."));
      }
    } catch (error) {
      console.error(error);
      closePending(
        toastId,
        getTranslation("Произошла ошибка при обработке заказа.")
      );
    }
  };

  const handleClickReceive = () => {
    playSound();
    dispatch(clearPopup());
    sendWsEvent(ws, WebSocketsClientEvents.SHOW_POPUP, {
      id: user_popup_id,
      ignored: false,
    });
    setTimer(null);
    return;
  };

  useEffect(() => {
    if (!timer) return;

    const intervalTimer = setInterval(() => {
      setTimer((prev) => {
        if (prev == null) return prev;
        if (prev <= 0) {
          dispatch(clearPopup());
          setTimer(null);
          clearInterval(intervalTimer);
          return prev;
        }
        return prev - 1;
      });
    }, 1000);

    return () => {
      clearInterval(intervalTimer);
    };
  }, []);

  const PURCHACE_MODAL = (
    <PortalModal isOpen={isOpen}>
      <div className={styles.modal}>
        <Image
          onClick={handleClose}
          className={styles.modal__close}
          src={close}
          alt="close"
        />
        <h1 className={styles.modal__title__up}>
          <span className={styles.modal__title__value}>
            {getTranslation("Специальное предложение!")}
          </span>
        </h1>
        <div className={styles.modal__content}>
          <Image
            width={128}
            className={styles.modal__image}
            src={gift}
            alt="image"
          />
          <h2 className={styles.modal__title}>
            {getTranslation(popUpData?.title || "")}
          </h2>
          <p className={styles.modal__description}>
            {popUpData?.description.includes("<br/>")
              ? popUpData.description.split("<br/>").map((line, index) => (
                  <React.Fragment key={index}>
                    {getTranslation(line)}
                    {index <
                      popUpData.description.split("<br/>").length - 1 && <br />}
                  </React.Fragment>
                ))
              : getTranslation(popUpData?.description || "")}
          </p>
          <p className={styles.modal__warning}>
            {getTranslation(popUpData?.warning || "")}
          </p>
          <div className={styles.modal__timer}>
            <Image width={32} src={clock} alt="clock" />
            <p>0:{timer}</p>
          </div>
          <p className={styles.modal__description}>
            {getTranslation(popUpData?.sub_description || "")}
          </p>
          <ActionButton
            onClick={handleClickBuy}
            className={`${styles.modal__actions} ${styles.mt_25} ${styles.modal__actions_xxl}`}
          >
            <Image
              alt="crossline"
              className={styles.modal__actions__cross}
              src={crossLine}
              width={50}
            />
            <span className={styles.modal__actions__price_old}>
              {popUpData?.purchase_data?.old_price}$
            </span>
            <span className={styles.modal__actions__price}>
              {popUpData?.purchase_data?.price_dollar}$
            </span>
            <CardBonus
              className={`${styles.modal__actions__bonus} ${styles.red}`}
              image={saleWrapper}
            >
              {getTranslation(popUpData?.purchase_data?.bonus || "")}
            </CardBonus>
          </ActionButton>
        </div>
      </div>
    </PortalModal>
  );

  const GIFT_MODAL = (
    <PortalModal isOpen={isOpen}>
      <div className={styles.modal}>
        <Image
          onClick={handleClose}
          className={styles.modal__close}
          src={close}
          alt="close"
        />
        <h1 className={styles.modal__title__up}>
          {getTranslation("ПОДАРОК!")}
        </h1>
        <div className={styles.modal__content}>
          <Image
            width={128}
            className={styles.modal__image}
            src={gift}
            alt="image"
          />
          <h2 className={styles.modal__title}>
            {getTranslation(popUpData?.title || "")}
          </h2>
          <p className={styles.modal__description}>
            {getTranslation(popUpData?.description || "")}
          </p>
          <p className={styles.modal__warning}>
            {getTranslation(popUpData?.warning || "")}
          </p>
          <div className={styles.modal__timer}>
            <Image width={32} src={clock} alt="clock" />
            <p>0:{timer}</p>
          </div>
          <p className={styles.modal__description}>
            {getTranslation(popUpData?.sub_description || "")}
          </p>
          <ActionButton
            onClick={handleClickReceive}
            className={` ${styles.mt_25}`}
          >
            <p className={styles.modal__actions__price}>
              {getTranslation("Активировать")}
            </p>
            <p className={`${styles.modal__actions__price} ${styles.small}`}>
              {getTranslation("(бесплатно)")}
            </p>
          </ActionButton>
        </div>
      </div>
    </PortalModal>
  );

  return (
    <>
      <AdsLayout />
      <DailyRewardLayout />
      {IS_GIFT ? GIFT_MODAL : PURCHACE_MODAL}
      <NotifyLayout />
    </>
  );
};

export default NotifyModal;

import { createSlice } from "@reduxjs/toolkit";
import { ExpeditionStateType } from "./interface";

const initialState: ExpeditionStateType = {
  expeditions: null,
};
export const expeditionSlice = createSlice({
  name: "expeditionSlice",
  initialState,
  reducers: {
    setExpeditions: (state, action) => {
      state.expeditions = action.payload.expeditions;
    },
    clearExpeditions: (state) => {
      state.expeditions = null;
    },
  },
});

export const { setExpeditions, clearExpeditions } = expeditionSlice.actions;
export default expeditionSlice.reducer;

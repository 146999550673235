import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { Application, Assets } from "pixi.js";

import { AssetType, atlasArray, skeletonArray } from "@/helpers";

type PreloadingContextType = {
  isPreloadingFinish: boolean;
  atlasArray: AssetType[];
  skeletonArray: AssetType[];
};

const Context = createContext<PreloadingContextType>(
  {} as PreloadingContextType
);

type PreloadingProviderProps = {
  children: React.ReactNode;
};

const PreloadingContext = ({ children }: PreloadingProviderProps) => {
  const [isPreloadingFinish, setIsPreloadingFinish] = useState(false);
  const appRef = useRef<Application | null>(null);

  const loadAssets = async () => {
    const allAssets = [...atlasArray, ...skeletonArray];
    const assetsToLoad = allAssets.filter((asset) => !Assets.get(asset.alias));

    if (assetsToLoad.length > 0) {
      Assets.add(assetsToLoad);
      const aliases = assetsToLoad.map((asset) => asset.alias);
      await Assets.load(aliases);
    }
    setIsPreloadingFinish(true);
  };

  const initApplication = async () => {
    const app = new Application();
    await app.init({
      width: 500,
      height: 500,
      backgroundAlpha: 0,
      roundPixels: true,
      resolution: window.devicePixelRatio || 1,
      autoDensity: true,
    });
    appRef.current = app;
  };

  useEffect(() => {
    if (typeof window !== "undefined" && !appRef.current) {
      initApplication();
    }
    return () => {
      if (appRef.current) {
        appRef.current.destroy(true, { children: true });
        appRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    loadAssets();
  }, []);

  return (
    <Context.Provider value={{ isPreloadingFinish, atlasArray, skeletonArray }}>
      {children}
    </Context.Provider>
  );
};

export const usePreloadingContext = () => useContext(Context);
export default PreloadingContext;



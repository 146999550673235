import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Image from "next/image";
import PortalModal from "@/components/PortalModal";
import ActionButton from "@/components/ActionButton";
import { preValidateXTRPurchase, validateXTRPurchase } from "@/api/payment";
import { sendWsEvent } from "@/utils/ws";
import { WebSocketsClientEvents } from "@/constants/events";
import { getTranslation } from "@/utils/location";
import useClickSound from "@/hooks/useClickSound";
import useToastManager from "@/hooks/useToastManager";
import { useInvoice } from "@/hooks/useInvoice";
import { usePopup } from "@/hooks/usePopUp";
import { RootState } from "@/store";
import close from "@/assets/icons/close.png";
import clock from "@/assets/icons/clock.png";
import starerPack from "@/assets/icons/statrerPack.png";
import {
    ACTIONS,
    getPopUpDataByNames,
} from "@/utils/getters/getPopUpDataByName";

import styles from "../../styles.module.scss";
import componentStyles from "./styles.module.scss";
import { clearStarterPack } from "@/store/starterPack/starterPackSlice";

type StarterPackPropsType = {
    time: string;
    onClose: () => void;
};

const StartPack = ({ time, onClose }: StarterPackPropsType) => {
    const dispatch = useDispatch();
    const playSound = useClickSound();
    const { notifyPending, updateToast, closePending } = useToastManager();
    const { createInvoice } = useInvoice();
    const { modal } = usePopup();

    const shopItem = useSelector((state: RootState) => state.starterPack.item);
    const isOpen = Boolean(shopItem);
    const ws = useSelector((state: RootState) => state.sockets.ws);
    const user_id = useSelector((state: RootState) => state.user.id);
    const isValidate = useSelector((state: RootState) => state.shop.isValidate);
    const webApp = useSelector((state: RootState) => state.app.webApp);


    const handleClickBuy = async () => {
        if (isValidate) return;

        playSound();

        const toastId = notifyPending("Ваш заказ обрабатывается");
        const shop_shopItem_id = shopItem?.id.toString() || "";
        const booster_type = "";
        const price = shopItem?.price_star || "";

        try {
            if (!webApp) {
                throw new Error("WebApp is not available");
            }

            preValidateXTRPurchase(
                user_id.toString(),
                shop_shopItem_id,
                booster_type,
                price
            );

            await createInvoice(
                webApp,
                getTranslation(shopItem?.title || ""),
                Number(price)
            );

            const { transaction_id, success } = await validateXTRPurchase(
                user_id.toString(),
                shop_shopItem_id,
                booster_type,
                price
            );

            if (success) {
                dispatch(clearStarterPack());
                const { title, textButton, text } = getPopUpDataByNames(
                    ACTIONS.BUY
                );
                modal(
                    title,
                    textButton,
                    text + shopItem?.title.toUpperCase() + "!",
                    starerPack
                );
                updateToast(
                    toastId,
                    getTranslation("Ваш заказ успешно обработан!")
                );
                sendWsEvent(ws, WebSocketsClientEvents.PURCHASE_RECEIVED, {
                    transaction_id: transaction_id,
                    purchase_type: "stars",
                });
            } else {
                closePending(
                    toastId,
                    getTranslation("Ошибка валидации заказа.")
                );
            }
        } catch (error) {
            console.error(error);
            closePending(
                toastId,
                getTranslation("Произошла ошибка при обработке заказа.")
            );
        }
    };

    return (
        <PortalModal isOpen={isOpen}>
            <div className={styles.modal}>
                <Image
                    onClick={onClose}
                    className={styles.modal__close}
                    src={close}
                    alt="close"
                />
                <h1 className={styles.modal__title__up}>
                    <span className={styles.modal__title__value}>
                        {getTranslation("СТАРТОВЫЙ НАБОР")}
                    </span>
                </h1>
                <div className={`${styles.modal__content} ${componentStyles.modal__content}`}>
                    <p className={componentStyles.modal__text}>{getTranslation("Выгода 400%! Это поможет вам продвинуться в рейтинге!")}</p>
                    <Image
                        className={componentStyles.modal__image}
                        src={starerPack}
                        quality={100}
                        alt="image"
                    />

                    <ActionButton
                        onClick={handleClickBuy}
                        className={`${styles.modal__actions} ${styles.modal__actions_xxl} ${componentStyles.modal__actions}`}
                    >
                        <span className={styles.modal__actions__price}>
                            {shopItem?.price_dollar}$
                        </span>
                    </ActionButton>

                    <div
                        className={`${styles.modal__timer} ${componentStyles.modal__timer}`}
                    >
                        <Image width={32} src={clock} alt="clock" />
                        <p>{time}</p>
                    </div>
                </div>
            </div>
        </PortalModal>
    );
};

export default StartPack;

import { createSlice } from "@reduxjs/toolkit";
import { SendReportStateType } from "./interfaces";

const initialState: SendReportStateType = {
    textReport: "",
    isOpenModal: false,
};
export const report = createSlice({
    name: "report",
    initialState,
    reducers: {
        setTextReport: (state, action) => {
            state.textReport = action.payload;
        },
        setIsOpenModal: (state, action) => {
            state.isOpenModal = action.payload;
        },
    },
});

export const { setTextReport, setIsOpenModal } = report.actions;
export default report.reducer;

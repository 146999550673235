import { createSlice } from "@reduxjs/toolkit";
import { IPopUpState } from "./interface";

const initialState: IPopUpState = {
    timer: null,
    item: null,
    user_popup_id: null,
};
export const starterPack = createSlice({
    name: "starterPack",
    initialState,
    reducers: {
        setStarterPack: (state, action) => {
            state.timer = action.payload.timer;
            state.item = action.payload.item;
        },

        clearStarterPack: (state) => {
            state.timer = null;
            state.user_popup_id = null;
            state.item = null;
        },
    },
});

export const { setStarterPack, clearStarterPack } = starterPack.actions;
export default starterPack.reducer;

import PortalModal from "@/components/PortalModal";
import close from "@/assets/icons/close.png";
import gift from "@/assets/icons/popugue.png";
import Image from "next/image";
import { getTranslation } from "@/utils/location";
import React from "react";
import gold from "@/assets/icons/coin.png";
import pearl from "@/assets/icons/pearls.png";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store";
import { clearNotification } from "@/store/notification/notificationSlice";

import styles from "../../styles.module.scss";
import ActionButton from "@/components/ActionButton";

const NotifyLayout = () => {
  const dispatch = useDispatch();
  const notification = useSelector(
    (state: RootState) => state.notification.notification
  );
  const handleClose = () => {
    dispatch(clearNotification());
  };

  return (
    <PortalModal isOpen={Boolean(notification)}>
      <div className={styles.modal}>
        <Image
          onClick={handleClose}
          className={styles.modal__close}
          src={close}
          alt="close"
        />
        <h1 className={styles.modal__title__up}>
          {getTranslation("ОПОВЕЩЕНИЕ")}
        </h1>
        <div className={styles.modal__content}>
          <Image
            width={256}
            className={styles.modal__image}
            src={gift}
            alt="image"
          />
          <h2 className={`${styles.modal__title} ${styles.mt_25}`}>
            {getTranslation("Работа автобустеров закончена!")}
          </h2>
          <h3 className={`${styles.modal__description} ${styles.mt_10}`}>
            {getTranslation("Вы получили:")}
          </h3>
          <div className={`${styles.modal__reward}`}>
            <Image
              src={gold}
              width={32}
              height={32}
              alt="gold icon"
              className={styles.modal__reward__image}
            />
            <span className={styles.modal__reward__value}>
              {notification?.collected_gold.toFixed(0)}
            </span>
            <span>{getTranslation('и')}</span>
            <Image
              src={pearl}
              width={32}
              height={32}
              alt="gold icon"
              className={styles.modal__reward__image}
            />
            <span className={styles.modal__reward__value}>
              {notification?.collected_pearl.toFixed(0)}
            </span>
          </div>
          <div className={`${styles.modal__actions} ${styles.mt_25}`}>
            <ActionButton className={styles.modal__actions_small} onClick={handleClose}>{getTranslation("Продолжить")}</ActionButton>
          </div>
        </div>
      </div>
    </PortalModal>
  );
};

export default NotifyLayout;

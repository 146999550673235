import { StaticImageData } from "next/image";

export interface IResponceStars {
    slug: string;
    status: IStatus;
}

export interface IPurchaseDTO {
    id: number;
    user_id: number;
    tx_id: string;
    title: string;
    image: string;
    double_bonus: string;
    purchase_type: PurhcaseType;
}

export type PurhcaseType = "ton" | "stars";

export type IStatus = "paid" | "cancelled";
export type IconType = "coins" | "pearls" | "autocollect";
export type CardStatusType = "complete" | "future" | "in_progress";

export const EVENTS_PURCHASE = {
    PAID: "paid",
    CLOSED: "invoiceClosed",
};

export interface IQuest {
    actions: IActionQuest[];
    id: number;
    name: string;
    coins: string;
    keys: string;
    pearls: string;
    auto_collect: string;
    task_type: TaskTaskType;
    is_active: boolean;
    target: number;
    url: string; 
    img: StaticImageData;
    icon_type: string;
}

export type TaskTaskType =
    | "subscription"
    | "voice"
    | "stories"
    | "ads"
    | "invite";

export interface IActionQuest {
    completion_time: string;
    id: number;
    progress: number;
    status: string;
    task_id: number;
    user_id: number;
}

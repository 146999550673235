import { useEffect, useCallback } from "react";

interface UseMoneyTagAdsReturn {
    showAd: () => Promise<void>;
}

export const useMoneyTagAds = (): UseMoneyTagAdsReturn => {
    useEffect(() => {
        if ((window as any).show_8679365) {
            return;
        }

        const tag = document.createElement("script");

        tag.src = "https://niphaumeenses.net/vignette.min.js";
        tag.dataset.zone = process.env.ADS_MONEYTAG_ZONE_ID;
        tag.dataset.sdk = process.env.ADS_MONEYTAG_SDK_ID;

        document.body.appendChild(tag);
    }, []);

    const showAd = useCallback((): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (typeof (window as any).show_8679365 === "function") {
                (window as any)
                    .show_8679365()
                    .then(() => {
                        resolve();
                    })
                    .catch((err: any) => {
                        reject(err);
                    });
            } else {
                reject(new Error("Ad SDK is not available"));
            }
        });
    }, []);

    return { showAd };
};

import { Leagues } from "./types";

export const LEAGUES: Leagues[] = ["Bronze", "Silver", "Gold"];

export const ROUTES = {
    WALLET: { url: "/wallet", title: "Кошелек" },
    MAIN_PAGE: { url: "/", title: "Меню" },
    LEADERBOARD: { url: "/leaderboard", title: "Лидерборд" },
    SHOP: { url: "/shop", title: "Магазин" },
    RULES: { url: "friends/rules", title: "Правила" },
    FRIENDS: { url: "/friends", title: "Друзья" },
    PROFILE: { url: "/profile", title: "Профиль" },
    SETTINGS: { url: "/settings", title: "Настройки" },
    QUESTS: { url: "/quests", title: "Квесты" },
    QUEST_ADS: { url: "/quest-ads", title: "Реклама квесты" },
    AUTOBOOSTERS_INFO: {
        url: "/autoboosters-info",
        title: "Информация о автобустерах",
    },
    DAILY_LEADERBOARD_INFO_GOLD: {
        url: "/daily-leaderboard-info-gold",
        title: "Информация о дневном лидерборде",
    },
    DAILY_LEADERBOARD_INFO_PEARL: {
        url: "/daily-leaderboard-info-pearl",
        title: "Информация о дневном лидерборде",
    },
    DAILY_LEADERBOARD: {
        url: "/daily-leaderboard",
        title: "Дневной лидерборд",
    },
    DAILY_FOND_INFO: {
        url: "/daily-fond-info",
        title: "Информация о дневном пуле наград",
    },
};

export const TEXT_REF =
    "Отправляйся в Airdrop-приключение! 💰 Зарабатывай золото и развивай свою империю. Получи бонус за вход в игру по ссылке";

import PortalModal from "@/components/PortalModal";
import close from "@/assets/icons/close.png";
import gift from "@/assets/icons/popugue.png";
import Image from "next/image";
import { getTranslation } from "@/utils/location";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store";
import { ShowPromiseResult } from "@/@types/adsgram";
import { useAdsgram } from "@/hooks/Ads/useAdsgram";
import { useCallback } from "react";
import ActionButton from "@/components/ActionButton";
import { clearAds, setAdsModal, setShowModalAds } from "@/store/ads/adsSlice";
import { sendWsEvent } from "@/utils/ws";
import { WebSocketsClientEvents } from "@/constants/events";
import pearl_reward from "@/assets/icons/reward_pearl_ads.png";
import ads from "@/assets/icons/ads.svg";
import key_reward from "@/assets/icons/reward_key_ads.png";
import { useAdSonar } from "@/hooks/Ads/useAdSonar";
import { useMoneyTagAds } from "@/hooks/Ads/useMoneyTagAds";
import useOnClicka from "@/hooks/Ads/useOnClicka";


import styles from "../../styles.module.scss";

const AdsLayout = () => {
  const dispatch = useDispatch();
  const { modal, showModal } = useSelector((state: RootState) => state.ads);
  const ws = useSelector((state: RootState) => state.sockets.ws);
  const step = modal?.type === "intro" ? 1 : 2;

  const handleClose = useCallback(() => {
    dispatch(clearAds());
    sendWsEvent(ws, WebSocketsClientEvents.ADS_CHECKED, {});
    dispatch(setShowModalAds(false));
  }, [dispatch]);

  const handleCompleteAds = useCallback(() => {
    sendWsEvent(ws, WebSocketsClientEvents.ADS_COMPLETE, {});
    dispatch(setShowModalAds(false));
  }, [dispatch]);

  const handleCollect = () => {
    sendWsEvent(ws, WebSocketsClientEvents.ADS_CHECKED, {});
    dispatch(setShowModalAds(false));
    dispatch(clearAds());
  };

  const onError = useCallback((result: ShowPromiseResult) => {
    console.log(JSON.stringify(result, null, 4));
  }, []);

  const showAdsgram = useAdsgram({
    blockId: "int-6383",
    onReward: handleCompleteAds,
    onError,
  });

  const { showAd: showAdSonar } = useAdSonar(
    process.env.ADS_SONAR_APP_ID || ""
  );

  const { showAd: showOnClickaAd } = useOnClicka("6048831");
  const { showAd: showMoneyTagAd } = useMoneyTagAds();
  const handleShowAds = useCallback(() => {
    if (modal?.provider_ads == "adsgram") {
      showAdsgram();
    } else if (modal?.provider_ads == "sonarads") {
      showAdSonar("production").then(() => handleCompleteAds);
    } else if (modal?.provider_ads == "onclicka") {
      showOnClickaAd().then(() => handleCompleteAds());
    } else if (modal?.provider_ads == "moneytag") {
      showMoneyTagAd().then(() => handleCompleteAds());
    } else {
      showAdsgram();
    }
    dispatch(setAdsModal(false));
    dispatch(clearAds());
  }, [showAdsgram]);

  if (!modal) return null;

  return (
    <PortalModal isOpen={showModal}>
      <div className={styles.modal}>
        <Image
          onClick={handleClose}
          className={styles.modal__close}
          src={close}
          alt="close"
        />
        <h1 className={`${styles.modal__title__up}`}>
          {getTranslation("РЕКЛАМА")}
        </h1>
        <div className={styles.modal__content}>
          <Image
            width={256}
            className={`${styles.modal__image} ${styles.mt_25}`}
            src={gift}
            alt="gift"
          />
          <h2 className={`${styles.modal__title} ${styles.mt_25}`}>
            {step == 1
              ? getTranslation("Посмотри рекламу и получите подарок!")
              : getTranslation("Ваша награда:")}
          </h2>
          <div className={`${styles.modal__reward__images}`}>
            <Image src={key_reward} width={40} quality={100} alt="key_reward" />
            <Image className={styles.mb_10} src={pearl_reward} width={80} alt="key_reward" />
          </div>
          <div className={`${styles.modal__actions} `}>
            {step == 1 ? (
              <ActionButton
                className={`${styles.modal__actions_large} ${styles.mt_10}`}
                onClick={handleShowAds}
              >
                <div className={styles.modal__actions__inner}>
                  <span className={styles.modal__text_white}>
                    {`${getTranslation("Смотреть")}`} {`${modal.count}/20`}
                  </span>
                  <Image
                    className={styles.modal__image}
                    src={ads}
                    width={24}
                    height={24}
                    alt="ads_icon"
                  />
                </div>
              </ActionButton>
            ) : (
              <ActionButton
                className={styles.modal__actions_small}
                onClick={handleCollect}
              >
                {getTranslation("Забрать")}
              </ActionButton>
            )}
          </div>
        </div>
      </div>
    </PortalModal>
  );
};

export default React.memo(AdsLayout);

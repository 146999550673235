import { CardStatusType, IconType } from "@/interfaces/interfaces";
import Image from "next/image";
import { getImage } from "@/components/NotifyModal/image";
import done_mark from "@/assets/icons/done.png";

import styles from "./styles.module.scss";
import { getTranslation } from "@/utils/location";

type DayCardProps = {
  day: string;
  amount: number;
  icon: IconType;
  status: CardStatusType;
};

export const DayCard: React.FC<DayCardProps> = ({
  day,
  amount,
  icon,
  status,
}) => {
  const classNameByStatus = styles[`card__${status}`] || styles.card__default;

  return (
    <div
      className={`${styles.card} ${
        day == "7" && styles.card__large
      } ${classNameByStatus}`}
    >
      {status == "complete" && (
        <Image
          src={done_mark}
          className={styles.card__mark}
          width={16}
          height={16}
          alt="done_mark"
        />
      )}
      <span className={styles.card__name}>
        {status == "in_progress" ? getTranslation("Сегодня") : getTranslation("День ") + day}
      </span>
      <Image
        src={getImage(icon)}
        width={24}
        height={24}
        alt={"reward type coin"}
      />
      <span className={styles.card__amount}>{amount}</span>
    </div>
  );
};

import { useEffect, useRef, useState, useCallback } from "react";

interface UseAdManagerReturn {
  balance: number;
  showAd: () => Promise<void>;
}

const useOnClicka = (id: string): UseAdManagerReturn => {
  const [balance, setBalance] = useState(0);
  const showAdRef = useRef<(() => Promise<void>) | null>(null);

  useEffect(() => {
    const initAdManager = async () => {
      try {
        // @ts-expect-error admanager
        const show = await window.initCdTma({ id });
        showAdRef.current = show;
      } catch (error) {
        console.error("No ads available:", error);
      }
    };

    initAdManager();
  }, [id]);

  const showAd = useCallback(async () => {
    if (showAdRef.current) {
      try {
        await showAdRef.current();
        setBalance((curr) => curr + 1);
      } catch (error) {
        console.error("Failed to show ad:", error);
      }
    } else {
      console.warn("Ad engine is not initialized yet.");
    }
  }, []);

  return { balance, showAd };
};

export default useOnClicka;

import { createSlice } from "@reduxjs/toolkit";
import { IQuestsState, IFormattedQuest, GroupQuestType } from "./interfaces";
import { IQuest } from "@/interfaces/interfaces";
import { formatImagePrize, formatPrize } from "@/utils/formatters/formatPrize";
import { STATUS_QUEST } from "./quests.constants";
import { getImageByType } from "@/utils/quest/getImageByType";

const initialState: IQuestsState = {
  groups: null,
};

export const questSlice = createSlice({
  name: "quests",
  initialState,
  reducers: {
    setQuests: (state, action) => {
      const formatUrl = (url: string) =>
        url.startsWith("t.me") ? "https://" + url : url;

      const formattedGroups = Object.keys(action.payload.groups).reduce(
        (acc, groupKey) => {
          const key = groupKey as GroupQuestType;
          if (!acc) return acc;
          acc[key] = action.payload.groups[key].map((quest: IQuest) => {
            const formattedPrize = formatPrize(
              quest.coins,
              quest.pearls,
              quest.auto_collect
            );
            const status = quest.actions[0].status === STATUS_QUEST.COMPLETED;
            const isInvite = quest.task_type === "invite";
            const target =
              isInvite || quest.task_type === "ads" ? quest.target : 0;

            return {
              id: quest.actions[0].id,
              task_id:quest.actions[0].task_id,
              title: quest.name || "Unknown Quest",
              prize: formattedPrize,
              link: formatUrl(quest.url) || "/",
              progress: quest.actions[0].progress,
              target: target,
              isNeedProgressBar: Boolean(target),
              imgPrize: formatImagePrize(formattedPrize),
              img: getImageByType(quest.icon_type),
              status: status,
              task_type: quest.task_type,
            } as IFormattedQuest;
          });
          return acc;
        },
        {} as IQuestsState["groups"]
      );
      if (!state.groups) {
        state.groups = { ads: [], friends: [], social: [] };
      }

      state.groups.ads = formattedGroups?.ads || [];
      state.groups.friends=formattedGroups?.friends || [];
      state.groups.social=formattedGroups?.social || [];
    },

    updateQuest: (state, action) => {
      if(!state.groups) return;

      const inviteQuest = state.groups.friends.find(
        (quest) => quest.task_id === action.payload.task_id
      );
      const adsQuest = state.groups.ads.find(
        (quest) => quest.task_id === action.payload.task_id
      );
      
      if (adsQuest) {
        adsQuest.progress = action.payload.progress;

        if (action.payload.progress === adsQuest.target) {
          adsQuest.status = true;
        }
      }

      if (inviteQuest) {
        inviteQuest.progress = action.payload.progress;

        if (action.payload.progress === inviteQuest.target) {
          inviteQuest.status = true;
        }
      }
    },
    setCompleteQuest: (state, action) => {
      const allQuests = [
        ...(state.groups?.ads || []),
        ...(state.groups?.friends || []),
        ...(state.groups?.social || []),
      ];

      const quest = allQuests.find((q) => q.id === action.payload);
      if (quest) {
        quest.status = true;
      }
    },
  },
});

export const { setQuests, setCompleteQuest, updateQuest } = questSlice.actions;
export default questSlice.reducer;

import { RewardType } from "@/store/dailyReward/interface";
import { DayCard } from "@/components/NotifyModal/Layouts/DailyReward/DayCard";

import styles from "./styles.module.scss";
import Image from "next/image";
import { getImage } from "@/components/NotifyModal/image";

type AdsRewardType =
  | Record<string, { icon: string; amount: number }>
  | { icon: string; amount: number }[];
type ModalRewardsContainerPropsType = {
  className?: string;
  rewards: AdsRewardType | RewardType | RewardType[] | null;
};

export const ModalRewardsContainer = ({
  className = "",
  rewards,
}: ModalRewardsContainerPropsType) => {
  const isArray = Array.isArray(rewards);
  return (
    <div className={`${styles.reward__container} ${className}`}>
      {isArray
        ? (rewards as RewardType[]).map((reward, index) => (
            <DayCard key={index} {...reward} />
          ))
        : Object.entries(
            rewards as Record<string, { icon: string; amount: number }>
          ).map(([key, reward], index) => (
            <div key={index} className={styles.reward__wrapper}>
              <Image
                src={getImage(reward.icon)}
                width={24}
                height={24}
                alt={reward.icon}
              />
              <span className={styles.reward__value}>{reward.amount}</span>
            </div>
          ))}
    </div>
  );
};

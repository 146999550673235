import coin from "@/assets/icons/coin.png";
import pearl from "@/assets/icons/pearls.png";
import key from "@/assets/icons/key.png";
import auto_collect from "@/assets/icons/autocollect.png";
import done from "@/assets/icons/done.png";

export const formatPrize = (
  coins: string,
  pearls: string,
  auto_collects: string
) => {

  const zeroCoinsText = "0 золота";
  const zeroPearlsText = "0 жемчуга";
  const zeroAutoBoosters = "0 автоСбора";

  if (coins == zeroCoinsText && auto_collects == zeroAutoBoosters) {
    return pearls;
  }

  if (pearls == zeroPearlsText && auto_collects == zeroAutoBoosters) {
    return coins;
  }

  return auto_collects;
};

export const formatImagePrize = (prize: string) => {
  const name = prize.toLowerCase();
  return name.includes("жемчуга")
    ? pearl
    : name.includes("ключ")
    ? key
    : name.includes("золота")
    ? coin
    : auto_collect;
};

import { useEffect, useCallback } from "react";

interface AdSonarResult {
    status: "showing" | "error" | "removed";
    message?: string;
}

interface UseAdSonarReturn {
    showAd: (adUnitName: string) => Promise<AdSonarResult>;
    removeAd: (adUnitName: string) => Promise<AdSonarResult>;
}

declare global {
    interface Window {
        Sonar: {
            show: (options: { adUnit: string }) => Promise<AdSonarResult>;
            remove: (options: { adUnit: string }) => Promise<AdSonarResult>;
        };
    }
}

export const useAdSonar = (appId: string): UseAdSonarReturn => {
    useEffect(() => {
        if (!window.Sonar) {
            const script = document.createElement("script");
            script.src = `https://static.sonartech.io/lib/1.0.0/sonar.js?appId=${appId}`;
            script.async = true;
            script.onload = () => console.log("Ad Sonar SDK loaded.");
            script.onerror = () =>
                console.error("Failed to load Ad Sonar SDK.");
            document.head.appendChild(script);
        }
    }, [appId]);

    const showAd = useCallback((adUnitName: string): Promise<AdSonarResult> => {
        return new Promise((resolve, reject) => {
            if (window.Sonar?.show) {
                window.Sonar.show({ adUnit: adUnitName })
                    .then(resolve)
                    .catch(reject);
            } else {
                reject(new Error("Ad Sonar SDK is not initialized."));
            }
        });
    }, []);

    const removeAd = useCallback(
        (adUnitName: string): Promise<AdSonarResult> => {
            return new Promise((resolve, reject) => {
                if (window.Sonar?.remove) {
                    window.Sonar.remove({ adUnit: adUnitName })
                        .then(resolve)
                        .catch(reject);
                } else {
                    reject(new Error("Ad Sonar SDK is not initialized."));
                }
            });
        },
        []
    );

    return { showAd, removeAd };
};

import styles from "./styles.module.scss";

export const generateRandomPosition = () => {
    const positions = [
      styles.ads__chest__left,      
      styles.ads__chest__right,     
      styles.ads__chest__left_down, 
    ];
  
    const randomIndex = Math.floor(Math.random() * positions.length);
    return positions[randomIndex];
  };

import { configureStore } from "@reduxjs/toolkit";
import hudReducer from "./hud/hudSlice";
import questsReducer from "./quests/questsSlice";
import leaderboardReducer from "./leaderboard/leaderboardSlice";
import islandReducer from "./island/islandSlice";
import appReducer from "./app/appSlice";
import userReducer from "./user/userSlice";
import socketsReducer from "./sockets/soketsSlice";
import shopReducer from "./shop/shopSlice";
import tutorialReducer from "./tutorial/tutorialSlice";
import notificationReducer from "./notification/notificationSlice";
import chestsReducer from "./chests/chestsSlice";
import dailyRewardReducer from "./dailyReward/dailyRewardSlice";
import adsReducer from "./ads/adsSlice";
import dailyLeaderboardReducer from "./dailyLeaderboard/dailyLeaderboardSlice";
import starterPackReducer from "./starterPack/starterPackSlice";
import expeditionReducer from './expedition/expeditionSlice'
import reportReducer from "./sendReport/sendReportSlice";

export const store = configureStore({
    reducer: {
        hud: hudReducer,
        quests: questsReducer,
        leaderboard: leaderboardReducer,
        island: islandReducer,
        app: appReducer,
        user: userReducer,
        sockets: socketsReducer,
        shop: shopReducer,
        tutorial: tutorialReducer,
        notification: notificationReducer,
        chests: chestsReducer,
        dailyReward: dailyRewardReducer,
        ads: adsReducer,
        dailyLeaderboard: dailyLeaderboardReducer,
        starterPack: starterPackReducer,
        expedition:expeditionReducer,
        report: reportReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import { setShowModalAds } from "@/store/ads/adsSlice";
import { AnimationsName } from "@/helpers";
import { Spine } from "@pixi/spine-pixi";
import { usePreloadingContext } from "@/context/usePreloading";
import { Application } from "pixi.js";
import { generateRandomPosition } from "./utils";

import styles from "./styles.module.scss";

const AdsChest = () => {
    const dispatch = useDispatch();
    
    const handleClick = () => {
        dispatch(setShowModalAds(true));
    };

    const appRef = useRef<Application | null>(null);
    const canvasRef = useRef<HTMLDivElement | null>(null);
    const adsRef = useRef<Spine | null>(null);
    const { atlasArray, skeletonArray, isPreloadingFinish } =
        usePreloadingContext();
    const setup = useCallback(async () => {
        if (canvasRef.current && typeof window !== "undefined") {
            appRef.current = new Application();
            await appRef.current.init({
                resizeTo: canvasRef.current,
                width: 75,
                height: 75,
                backgroundAlpha: 0,
                backgroundColor: "rgba(0,0,0,0)",
                roundPixels: true,
                resolution: window.devicePixelRatio || 1,
                autoDensity: true,
            });
            canvasRef.current.appendChild(appRef.current.canvas);
        }
    }, []);

    const setupAnim = useCallback(async () => {
        const app = appRef.current;

        const skeletonItem = skeletonArray.find((item) =>
            item.alias.includes(AnimationsName.ADS_CHEST_SKELETON)
        );
        const atlasItem = atlasArray.find((item) =>
            item.alias.includes(AnimationsName.ADS_CHEST_ATLAS)
        );

        if (!skeletonItem || !atlasItem) {
            console.warn(
                `No skeleton or atlas found for gender ${AnimationsName.ADS_CHEST}`
            );
            return;
        }

        const skeletonAlias = skeletonItem.alias;
        const atlasAlias = atlasItem.alias;

        if (!skeletonAlias || !atlasAlias) return;

        const spine = Spine.from({
            skeleton: skeletonAlias,
            atlas: atlasAlias,
            scale: 0.25,
        });

        adsRef.current = spine;

        adsRef.current.skeleton.setSlotsToSetupPose();

        adsRef.current.visible = false;
        app?.stage?.addChild(adsRef.current);

        if (app?.renderer) {
            adsRef.current.x = app.renderer.width / 2;
            adsRef.current.y = app.renderer.height / 2;
        }

        const animationState = adsRef.current.state;
        animationState.setAnimation(0, AnimationsName.ADS_CHEST, true);
        animationState.timeScale = 0.5;
        requestAnimationFrame(() => {
            if (adsRef.current) {
                adsRef.current.visible = true;
            }
        });
    }, []);

    const init = useCallback(async () => {
        if (!appRef.current) await setup();

        if (adsRef.current) {
            appRef.current?.stage.removeChild(adsRef.current);
            adsRef.current.destroy();
            adsRef.current = null;
        }

        await setupAnim();
    }, [setup, setupAnim]);

    useEffect(() => {
        if (isPreloadingFinish) init();
        return () => {
            if (adsRef.current) {
                appRef.current?.stage.removeChild(adsRef.current);
                adsRef.current.destroy({ children: true });
                adsRef.current = null;
            }
            if (appRef.current) {
                appRef.current.destroy(true, { children: true });
                appRef.current = null;
            }
        };
    }, [init, isPreloadingFinish]);
    const classNamePosition = useMemo(() => generateRandomPosition(), []);

    return (
        <div
            className={`${styles.ads} ${classNamePosition}`}
            onClick={handleClick}
            ref={canvasRef}
        ></div>
    );
};

export default AdsChest;
